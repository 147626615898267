import { ALLOWED_TAGS } from './config';
let $ = window.$;
let twindow = window;
twindow.rto = twindow.rto || {};
let container;
let tale;
let sb = [];
let cfg;
twindow.rto.copyToClipboard = copyToClipboard;
const createSbI = (stID, selectedCID) => ({ stID, selectedCID });
const sanitize = (dirtyHtml) => twindow.DOMPurify.sanitize(dirtyHtml, { ALLOWED_TAGS });
twindow.rto.createTimeline = (elementID, rtl, config = {}) => {
    container = $('#' + elementID);
    tale = rtl;
    cfg = config;
    if (cfg.preselectCIDs && Array.isArray(cfg.preselectCIDs) && cfg.preselectCIDs.length > 0) {
        sb = createSBWithPreCIDs(cfg.preselectCIDs);
    }
    else {
        sb = [createSbI(rtl.start)];
    }
    container.addClass('reactive-timeline');
    render();
    initEventBindings();
};
function createSBWithPreCIDs(preselectCIDs) {
    let st = tale.storylets[tale.start];
    let ch;
    let sbArr = [];
    for (let index = 0; index < preselectCIDs.length; ++index) {
        ch = tale.choices[preselectCIDs[index]];
        if (st.choices.includes(ch.id)) {
            sbArr.push(createSbI(st.id, ch.id));
            st = tale.storylets[ch.next];
        }
        else {
            return sbArr;
        }
    }
    sbArr.push(createSbI(st.id));
    return sbArr;
}
function render() {
    if (typeof (cfg.timelineWillRender) === 'function') {
        cfg.timelineWillRender();
    }
    let htm = '';
    for (let i = 0; i < sb.length; i++) {
        let sbI = sb[i];
        htm += createSbiHtm(sbI, i, i === (sb.length - 1));
    }
    container.html(htm);
    scrollToSbi();
    scrollToSelectedChoices();
    if (typeof (cfg.timelineDidRender) === 'function') {
        cfg.timelineDidRender();
    }
}
function createSbiHtm(sbI, indx, isLastItem) {
    let st = tale.storylets[sbI.stID];
    let choicesHtm = createChoicesHtm(st.choices, sbI.selectedCID);
    let choiceBallHtm = createChoiceBalls(st.choices);
    let stProcessedTxt = (typeof (twindow.rto.process) === "function") ? twindow.rto.process(st.text) : st.text;
    stProcessedTxt = sanitize(stProcessedTxt);
    let clpsClass = !isLastItem ? 'clps' : '';
    return (`
        <div class="storyboard-item" data-sbindx="${indx}">
            <div class="storylet component-box ${clpsClass}" data-stid="${st.id}">
                <div class="joiner"></div>
                <div class="name-label"> 
                    <i class="fas fa-chevron-up ico up"></i>
                    <i class="fas fa-chevron-down ico down"></i>
                    Storylet
                    <span class="idchip">ID: ${st.id} <i class="far fa-copy ico-copy"></i></span>
                </div>
                <div class="wrap-text">
                    <div class="st-text">${stProcessedTxt}</div>
                </div>
            </div>
            <div class="choices component-box ${st.choices.length <= 0 ? 'hidden' : ''}">
                <div class="joiner"></div>
                <div class="name-label">Choices (${st.choices.length})</div>
                <div class="balls">${choiceBallHtm}</div>
                <div class="inner-container">${choicesHtm}</div>
            </div>
        </div>
        `);
}
function createChoicesHtm(choiceIDArr, selectedCID) {
    return choiceIDArr.reduce((htm, cid, i) => {
        let c = tale.choices[cid];
        return htm + `
            <div class="choice ${cid === selectedCID ? 'selected' : ''}" data-cid="${c.id}">
                <div class="c-i">
                    Choice ${i + 1}
                    <span class="idchip">ID: ${c.id} <i class="far fa-copy ico-copy"></i></span>
                </div>
                <span class="c-text">${sanitize(c.text)}</span>
            </div>
        `;
    }, '');
}
function createChoiceBalls(choiceIDArr) {
    return choiceIDArr.reduce((htm, cid, i) => {
        return htm + `
            <span class="c-i-ball">${i + 1}</span>
        `;
    }, '');
}
function scrollToSbi(index = sb.length - 1) {
    let sbI = container.find('.storyboard-item').eq(index);
    $('html, body').animate({
        scrollTop: ((sbI && sbI.offset()) ? sbI.offset().top : 100) - 100
    }, 500);
}
function scrollToSelectedChoices() {
    container.find('.choices .inner-container').each(function () {
        let chSelected = $(this).find('.choice.selected');
        if (chSelected.length === 0)
            return;
        let shiftLeft = chSelected.eq(0).offset().left - 41;
        $(this).scrollLeft(shiftLeft);
    });
}
function addSbi(stID, indx, selectedCID) {
    sb = sb.slice(0, indx + 1);
    sb[indx].selectedCID = selectedCID;
    sb.push({ stID });
    render();
}
function initEventBindings() {
    container.off();
    container.on('click', '.storylet .name-label', function () {
        $(this).closest('.storylet').toggleClass('clps');
    });
    container.on('click', '.choice', function () {
        let cid = $(this).attr('data-cid');
        let c = tale.choices[cid];
        let stID = c.next;
        let indx = parseInt($(this).closest('.storyboard-item').attr('data-sbindx'));
        addSbi(stID, indx, cid);
    });
    container.on('click', '.c-i-ball', function () {
        let index = parseInt($(this).text()) - 1;
        let chContainer = $(this).closest('.choices').find('.inner-container');
        let chWidth = chContainer.find('.choice ').width();
        let leftShift = index * chWidth;
        chContainer.animate({ scrollLeft: leftShift }, 500);
    });
    container.on('click', '.idchip', function (e) {
        e.stopPropagation();
        e.preventDefault();
        const stid = $(e.target).closest('.idchip').text().split(':')[1].trim();
        copyToClipboard(stid, "ID copied to clipboard ...");
    });
}
function copyToClipboard(value, successMsg) {
    var tempInput = document.createElement("input");
    tempInput.value = value;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
    if (typeof (twindow.notify) === 'function') {
        twindow.notify(successMsg || 'Copied to clipboard ... ');
    }
}
